import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const AddModal = ({
  showAddModal,
  setShowAddModal,
  facility,
  setFacility,
  facilities,
  setFacilities,
}) => {
  const [facilityError, setFacilityError] = useState(false);
  const [facilityErrorMsg, setFacilityErrorMsg] = useState("");
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();

  const handleModalClose = () => {
    setFacility("");
    setFacilityError(false);
    setShowAddModal(false);
  };

  const handleFacilityChange = (e) => {
    const { value } = e.target;
    setFacility(value);
    if (value.trim() === "") {
      setFacilityError(true);
      setFacilityErrorMsg("Facility is required");
    } else {
      setFacilityError(false);
    }
  };

  const addFacility = () => {
    if (facility.trim() === "") {
      setFacilityError(true);
      setFacilityErrorMsg("Facility is required");
    } else if (facilities.some((fac) => fac.facility === facility)) {
      setFacilityError(true);
      setFacilityErrorMsg("Facility already exists");
    } else
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post("/facility", {
          facility,
        })
        .then((response) => {
          setFacilities([...facilities, response.data]);
          toast.success("Facility has been added successfully!");
          handleModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
  };
  return (
    <Dialog
      open={showAddModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Add Facility</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="facility"
          label="Facility"
          type="text"
          fullWidth
          variant="standard"
          value={facility}
          error={facilityError}
          helperText={facilityError ? facilityErrorMsg : ""}
          onChange={handleFacilityChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={addFacility} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
