import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Box,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TablePagination,
  Paper,
  Stack,
  Chip,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  Close as CloseIcon,
  BackupTable as ExportIcon,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { debounce } from "@mui/material/utils";
import { toast } from "react-toastify";
import TopNav from "../components/topnav";
import { ADMIN, MEDICAL_ASSISTANT, secureApi } from "../config";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

function createData(
  id,
  chart,
  patient_info,
  contact_info,
  insurance,
  employer,
  date_of_service,
  note_status,
  form_status,
  status,
  patient_notes
) {
  return {
    id,
    chart,
    patient_info,
    contact_info,
    insurance,
    employer,
    date_of_service,
    note_status,
    form_status,
    status,
    patient_notes,
  };
}

const headCells = [
  {
    id: "chart",
    label: "Chart #",
  },
  {
    id: "patientName",
    label: "Patient Name",
  },
  {
    id: "dateOfBirth",
    label: "D.O.B",
  },
  {
    id: "dateOfService",
    label: "D.O.S",
  },
  {
    id: "noteStatus",
    label: "Note Status",
  },
  {
    id: "formStatus",
    label: "Form Status",
  },
  {
    id: "status",
    label: "Status",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const tableHeads = [
  { headerName: "First Name", field: "first_name" },
  { headerName: "Last Name", field: "last_name" },
  { headerName: "Gender", field: "gender" },
  { headerName: "Email Address", field: "email" },
  { headerName: "Mobile Phone Number", field: "contact_number" },
  { headerName: "Insurance", field: "insurance" },
  { headerName: "Prefered Contact", field: "prefered_contact" },
  { headerName: "Patient EMR ID", field: "chart" },
  { headerName: "DOB", field: "date_of_birth" },
  { headerName: "Date of Service", field: "date_of_service" },
  { headerName: "Location/Office", field: "clinic" },
  { headerName: "Referred By", field: "referredBy" },
  { headerName: "Chief Complaint", field: "pains" },
  { headerName: "Provider", field: "provider" },
  { headerName: "Employer", field: "employer" },
  { headerName: "Procedures Done with Dr Alex", field: "procedures" },
  { headerName: "Past Medical History", field: "past_medical_history" },
  { headerName: "Past Surgical History", field: "past_surgical_history" },
];

export default function Patients() {
  const { setLoggedIn, user } = useContext(Context);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [patients, setPatients] = useState([]);
  const [totalPatients, setTotalPatients] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [patientID, setPatientID] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [locations, setLocations] = useState([]);
  const [refers, setRefers] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteButtonClick = (event, patient_id) => {
    if (user && user.role === ADMIN) {
      setPatientID(patient_id);
      setDeleteModalOpen(true);
    } else {
      toast.error("You don't have permission to delete patients");
    }
  };

  const deletePatient = () => {
    if (user && user.role === ADMIN) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/patient/${patientID}`)
        .then(() => {
          toast.success("Patient has been deleted successfully!");
          window.location.href = window.location.href;
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("You don't have permission to delete patients!");
    }

    setDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setDeleteModalOpen(false);
  };

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert data with headers to sheet
    const dataWithHeaders = patients.map((patient) => {
      patient["first_name"] = patient["patient_info"]["first_name"];
      patient["last_name"] = patient["patient_info"]["last_name"];
      patient["gender"] = patient["patient_info"]["gender"];
      patient["date_of_birth"] = patient["patient_info"]["date_of_birth"];
      const office_location_id = patient["patient_info"]["clinic"];
      if (office_location_id) {
        const officeLocations = locations.filter(
          (loc) => loc._id === office_location_id
        );
        if (officeLocations.length > 0) {
          patient["clinic"] = officeLocations[0].location;
        }
      }
      const referredBy = patient["patient_info"]["referredBy"];
      if (referredBy) {
        const filteredRefers = refers.filter((ref) => ref._id === referredBy);
        if (filteredRefers.length > 0) {
          patient["referredBy"] = filteredRefers[0].refer;
        }
      }
      patient["provider"] = "Dr Alex";
      patient["email"] = patient["contact_info"]["email_address"];
      patient["contact_number"] = patient["contact_info"]["contact_number"];
      patient["prefered_contact"] =
        patient["contact_info"]["preferred_contact"];
      patient["pains"] = [];
      patient["procedures"] = [];
      patient["past_medical_history"] = [];
      patient["past_surgical_history"] = [];

      patient["patient_notes"].forEach((note) => {
        if (note.pains && Array.isArray(note.pains)) {
          const painNames = [...new Set(note.pains.map((pain) => pain.name))];
          patient["pains"] = [...new Set([...patient["pains"], ...painNames])];
        }

        if (note.pastMedicalHistory && Array.isArray(note.pastMedicalHistory)) {
          patient["past_medical_history"] = [
            ...new Set([
              ...patient["past_medical_history"],
              ...note.pastMedicalHistory,
            ]),
          ];
        }

        if (
          note.pastSurgicalHistory &&
          Array.isArray(note.pastSurgicalHistory)
        ) {
          patient["past_surgical_history"] = [
            ...new Set([
              ...patient["past_surgical_history"],
              ...note.pastSurgicalHistory,
            ]),
          ];
        }

        if (
          note.performedProcedure &&
          note.performedProcedure.procedure &&
          Array.isArray(note.performedProcedure.procedure)
        ) {
          patient["procedures"] = [
            ...new Set([
              ...patient["procedures"],
              ...note.performedProcedure.procedure,
            ]),
          ];
        }
      });
      patient["pains"] = patient["pains"].join("; ");
      patient["procedures"] = patient["procedures"].join("; ");
      patient["past_medical_history"] =
        patient["past_medical_history"].join("; ");
      patient["past_surgical_history"] =
        patient["past_surgical_history"].join("; ");

      const obj = {};
      tableHeads
        .filter((column) => column.headerName)
        .forEach((column) => {
          if (column.headerName) obj[column.headerName] = patient[column.field];
        });
      return obj;
    });
    // Convert data with headers to sheet
    const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Set background color for the first 2 column headers
    const firstTwoHeaders = tableHeads
      .filter((column) => column.headerName)
      .slice(0, 2)
      .map((column) => column.headerName);

    firstTwoHeaders.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellAddress]) ws[cellAddress] = { t: "s", v: header };
      ws[cellAddress].s = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: "FFFF00" }, // Yellow background color
        },
        font: {
          bold: true,
        },
      };
    });

    // Create workbook and add sheet with data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");

    // Write workbook to buffer in xlsx format
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert buffer to Blob with specified MIME type
    const data = new Blob([excelBuffer], { type: fileType });

    // Save data as a file with specified filename
    FileSaver.saveAs(data, `patients` + fileExtension);
  };

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .post(`/patients`, {
        page,
        rowsPerPage,
        searchStr,
        order,
        orderBy,
      })
      .then((response) => {
        let { patients, total_patients } = response.data;
        patients = patients.map((patient) => {
          patient["dateOfService"] = patient["dateOfService"].map(
            (dos) =>
              dos.split("/")[1] +
              "/" +
              dos.split("/")[2] +
              "/" +
              dos.split("/")[0]
          );
          if (orderBy === "dateOfService") {
            if (order === "desc") patient["dateOfService"].sort().reverse();
            else patient["dateOfService"].sort();
          }

          return createData(
            patient["_id"],
            patient["chart"],
            patient.patientInfo,
            patient.contactInfo,
            patient["insuranceInformation"]["insurance_provider"],
            patient["employment"]["employer"],
            patient["dateOfService"].join(", "),
            patient["noteStatus"],
            patient["formStatus"],
            patient["status"],
            patient["patient_notes"]
          );
        });
        setPatients(patients);
        setTotalPatients(total_patients);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, [page, rowsPerPage]);

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get("/office-locations")
      .then((response) => {
        if (response.data["office_locations"]) {
          setLocations(response.data["office_locations"]);
        }
      });

    secureApi(window.localStorage.getItem("alex-med-token"))
      .get("/refers")
      .then((response) => {
        if (response.data["refers"]) {
          setRefers(response.data["refers"]);
        }
      });
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalPatients) : 0;

  const viewPatientData = (e, patient_id) => {
    if (e.target.tagName === "TD") navigate(`/patient/${patient_id}`);
    // window.open(`/patient/${patient_id}`, '_blank');
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/patients`, {
            page: 0,
            rowsPerPage,
            searchStr: request.searchStr,
            order: request.order,
            orderBy: request.orderBy,
          })
          .then((response) => {
            callback(response.data);
          })
          .catch((e) => {
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    fetch({ searchStr: searchStr, order, orderBy }, (results) => {
      if (active) {
        let { patients, total_patients } = results;
        patients = patients.map((patient) => {
          patient["dateOfService"] = patient["dateOfService"].map(
            (dos) =>
              `${dos.split("/")[1]}/${dos.split("/")[2]}/${dos.split("/")[0]}`
          );
          if (orderBy === "dateOfService") {
            if (order === "desc") patient["dateOfService"].sort().reverse();
            else patient["dateOfService"].sort();
          }
          return createData(
            patient["_id"],
            patient["chart"],
            patient["patientInfo"],
            patient["contactInfo"],
            patient["insuranceInformation"]["insurance_provider"],
            patient["employment"]["employer"],
            patient["dateOfService"].join(", "),
            patient["noteStatus"],
            patient["formStatus"],
            patient["status"],
            patient["patient_notes"]
          );
        });
        setPatients(patients);
        setTotalPatients(total_patients);
        setPage(0);
      }
    });

    return () => {
      active = false;
    };
  }, [searchStr, fetch, order, orderBy]);

  const createSortHandler = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TopNav item="patients" />
      <Box margin="30px">
        <div style={{ display: "flex" }}>
          {user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && (
            <div style={{ width: "100%" }}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  style={{ marginBottom: "10px" }}
                  onClick={() => window.open("/patient", "_blank")}
                >
                  Add New Patient
                </Button>
              </Stack>
            </div>
          )}
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Stack direction="row" sx={{ float: "right" }} gap={1}>
              <Button
                variant="contained"
                style={{ width: "fit-content" }}
                startIcon={<ExportIcon />}
                onClick={exportToExcel}
              >
                Export Excel
              </Button>
              <TextField
                size="small"
                label="Search"
                variant="outlined"
                style={{ float: "right" }}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
              />
            </Stack>
          </div>
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow style={{ background: "grey" }}>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      padding={"normal"}
                      style={{ color: "white" }}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.id === "patientName" ||
                      headCell.id === "dateOfBirth" ||
                      headCell.id === "dateOfService" ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(e) => createSortHandler(e, headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                  {user.role === ADMIN && (
                    <TableCell
                      key="operation"
                      padding={"normal"}
                      style={{ color: "white" }}
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {patients.map((row) => {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => viewPatientData(event, row["id"])}
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{row.chart}</TableCell>
                      <TableCell>{`${row.patient_info.first_name} ${row.patient_info.last_name}`}</TableCell>
                      <TableCell>{row.patient_info.date_of_birth}</TableCell>
                      <TableCell>{row.date_of_service}</TableCell>
                      <TableCell>
                        {row.note_status ? (
                          <Chip label="Signed" color="success" />
                        ) : (
                          <Chip label="Unsigned" color="warning" />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.form_status ? (
                          <Chip label="Signed" color="success" />
                        ) : (
                          <Chip label="Unsigned" color="warning" />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.status === "active" ? (
                          <Chip label="active" color="success" />
                        ) : (
                          <Chip label="inactive" color="warning" />
                        )}
                      </TableCell>
                      {user.role === ADMIN && (
                        <TableCell>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={(e) =>
                              handleDeleteButtonClick(e, row["id"])
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {totalPatients < 1 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={9}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      No patients yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              50,
              100,
              { label: "All", value: -1 },
            ]}
            component="div"
            count={totalPatients}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <BootstrapDialog
          fullScreen={fullScreen}
          open={deleteModalOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Are you sure to delete the patient?</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText>
              If you agree, all information related to the patient will be
              deleted!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deletePatient} autoFocus variant="contained">
              Agree
            </Button>
            <Button onClick={handleCancel} variant="contained" color="error">
              Disagree
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    </>
  );
}
