import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = ({ open, billing, handleClose, handleDeleteBilling }) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const deleteBilling = () => {
    if (billing && billing["_id"])
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/billing/${billing["_id"]}`)
        .then(() => {
          toast.success("Billing information has been deleted successfully!");
          handleDeleteBilling(billing["_id"]);
          handleClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.warn("Unavailable billing");
  };

  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Are you sure to delete the billing information?
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          If you agree, all the billing information will be deleted!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteBilling} autoFocus variant="contained">
          Agree
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Disagree
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteModal;
