import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Button,
  ButtonGroup,
  IconButton,
  TextField,
} from "@mui/material";
import {
  BackupTable as ExportIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DateRangePicker } from "rsuite";
import EditModal from "../billing/EditModal";
import DeleteModal from "../billing/DeleteModal";
import { secureApi } from "../../config";
import { Context } from "../../context";

const tableHeads = [
  { headerName: "Insurance", field: "insurance" },
  { headerName: "Status", field: "status" },
  { headerName: "Service Date", field: "date_of_service" },
  { headerName: "Cost Share Paid", field: "cost_share_paid" },
  { headerName: "Date Billed", field: "date_billed" },
  { headerName: "Total Charges", field: "total_charges" },
  { headerName: "Total receipts by mail", field: "total_receipts_by_mail" },
  { headerName: "Total receipts by Tebra", field: "total_receipts_by_tebra" },
  { headerName: "Receipt Date", field: "receipt_date" },
  { headerName: "Total balance", field: "total_balance" },
  { headerName: "Sent to patient?", field: "sent_to_patient" },
  { headerName: "Collected from patient?", field: "collected_from_patient" },
  { headerName: "Appeal needed?", field: "appeal_needed" },
  { headerName: "Appeal Date", field: "appeal_date" },
  { headerName: "Comments", field: "comments" },
];

const Billing = ({ patient_id }) => {
  const [billings, setBillings] = useState([]);
  const [filteredBillings, setFilteredBillings] = useState([]);
  const [billing, setBilling] = useState({});
  const [searchStr, setSearchStr] = useState("");
  const [filterDates, setFilterDates] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleEditModalOpen = (billing) => {
    setBilling(billing);
    setEditModalOpen(true);
  };

  const handleDeleteModalOpen = (billing) => {
    setBilling(billing);
    setDeleteModalOpen(true);
  };

  const handleEditModalClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteBilling = (billingID) => {
    const newBillings = [...billings];
    const billingIndex = newBillings.findIndex((b) => b._id === billingID);
    if (billingIndex !== -1) {
      // Remove the billing using splice
      newBillings.splice(billingIndex, 1);
      setBillings(newBillings);
    }
  };

  const handleUpdateBilling = (billing) => {
    const newBillings = [...billings];
    // Find and update the specific billing
    const billingIndex = newBillings.findIndex((b) => b._id === billing._id);
    if (billingIndex !== -1) {
      // Update the billing at the found index
      newBillings[billingIndex] = billing;
      setBillings(newBillings);
      return;
    }
  };

  const exportExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert data with headers to sheet
    const dataWithHeaders = billings.map((billing) => {
      const obj = {};
      tableHeads
        .filter((column) => column.headerName)
        .forEach((column) => {
          if (column.headerName) obj[column.headerName] = billing[column.field];
        });
      return obj;
    });
    // Convert data with headers to sheet
    const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Set background color for the first 2 column headers
    const firstTwoHeaders = tableHeads
      .filter((column) => column.headerName)
      .slice(0, 2)
      .map((column) => column.headerName);

    firstTwoHeaders.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellAddress]) ws[cellAddress] = { t: "s", v: header };
      ws[cellAddress].s = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: "FFFF00" }, // Yellow background color
        },
        font: {
          bold: true,
        },
      };
    });

    // Create workbook and add sheet with data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");

    // Write workbook to buffer in xlsx format
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert buffer to Blob with specified MIME type
    const data = new Blob([excelBuffer], { type: fileType });

    // Save data as a file with specified filename
    FileSaver.saveAs(data, `billing` + fileExtension);
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/billings/${patient_id}`)
        .then((response) => {
          const { billings } = response.data;
          if (billings) {
            setBillings(billings);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  }, [patient_id]);

  useEffect(() => {
    if (searchStr || filterDates) {
      // Filter billings based on search string and dates
      const filteredBillings = billings.filter((billing) => {
        // Check if billing matches search string
        const matchesSearch =
          !searchStr ||
          [
            billing.insurance,
            billing.status,
            billing.cpt_code,
            billing.diagnosis_icd_code,
            billing.modifiers,
          ].some(
            (field) =>
              field &&
              field.toLowerCase().includes(searchStr.toLowerCase().trim())
          );

        // Check if billing matches date range
        let matchesDates = true;
        if (filterDates && filterDates[0] && filterDates[1]) {
          const startDate = new Date(filterDates[0]);
          const endDate = new Date(filterDates[1]);

          if (billing.date_of_service) {
            const [month, day, year] = billing.date_of_service.split("/");
            const serviceDate = new Date(year, month - 1, day); // month is 0-based in JS
            matchesDates = serviceDate >= startDate && serviceDate <= endDate;
          } else {
            matchesDates = false;
          }
        }

        return matchesSearch && matchesDates;
      });

      setFilteredBillings(filteredBillings);
    } else {
      setFilteredBillings(billings);
    }
  }, [searchStr, filterDates, billings]);

  return (
    <Box sx={{ margin: 1 }}>
      <div style={{ width: "100%" }}>
        <Stack
          direction="row"
          sx={{ float: "right", marginBottom: "10px" }}
          gap={1}
        >
          <Button
            variant="contained"
            style={{ width: "fit-content" }}
            startIcon={<ExportIcon />}
            onClick={exportExcel}
          >
            Export Excel
          </Button>
          <DateRangePicker
            format="MM/dd/yyyy"
            character=" - "
            placeholder="Date of Service"
            size="lg"
            value={filterDates}
            onChange={(value) => setFilterDates(value)}
          />
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            style={{ float: "right" }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />
        </Stack>
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow sx={{ background: "#fce5cd" }}>
              <TableCell align="right">Insurance</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Service Date</TableCell>
              <TableCell align="right">Cost share paid</TableCell>
              <TableCell align="right">Date billed</TableCell>
              <TableCell align="right">CPT Code</TableCell>
              <TableCell align="right">Diagnosis ICD Code</TableCell>
              <TableCell align="right">Modifiers</TableCell>
              <TableCell align="right">Charge</TableCell>
              <TableCell align="right">Total Pay</TableCell>
              <TableCell align="right">Adjusted Amount</TableCell>
              <TableCell align="right">A/R</TableCell>
              <TableCell align="right">TOT_PR</TableCell>
              <TableCell align="right">PR_PAID</TableCell>
              <TableCell align="right">PR1</TableCell>
              <TableCell align="right">PR2</TableCell>
              <TableCell align="right">PR3</TableCell>
              <TableCell align="right">PR100</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBillings &&
              filteredBillings.length > 0 &&
              filteredBillings.map((billing, index) => (
                <TableRow
                  sx={{
                    "& > *": {
                      borderBottom: "unset",
                      background: theme.palette.action.hover,
                    },
                  }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    {billing.insurance}
                  </TableCell>
                  <TableCell align="right">{billing.status}</TableCell>
                  <TableCell align="right">{billing.date_of_service}</TableCell>
                  <TableCell align="right">{billing.cost_share_paid}</TableCell>
                  <TableCell align="right">{billing.date_billed}</TableCell>
                  <TableCell align="right">{billing.cpt_code}</TableCell>
                  <TableCell align="right">
                    {billing.diagnosis_icd_code}
                  </TableCell>
                  <TableCell align="right">{billing.modifiers}</TableCell>
                  <TableCell align="right">{billing.charge}</TableCell>
                  <TableCell align="right">{billing.total_pay}</TableCell>
                  <TableCell align="right">{billing.adjusted_amount}</TableCell>
                  <TableCell align="right">{billing.a_r}</TableCell>
                  <TableCell align="right">{billing.tot_pr}</TableCell>
                  <TableCell align="right">{billing.pr_paid}</TableCell>
                  <TableCell align="right">{billing.pr1}</TableCell>
                  <TableCell align="right">{billing.pr2}</TableCell>
                  <TableCell align="right">{billing.pr3}</TableCell>
                  <TableCell align="right">{billing.pr100}</TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="small">
                      <IconButton
                        variant="contained"
                        color="warning"
                        onClick={() => handleEditModalOpen(billing)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteModalOpen(billing)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            {filteredBillings && filteredBillings.length === 0 && (
              <TableRow>
                <TableCell colSpan={19} sx={{ textAlign: "center" }}>
                  <Typography variant="h6">No Billing Information</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        open={deleteModalOpen}
        billing={billing}
        handleClose={handleDeleteModalClose}
        handleDeleteBilling={handleDeleteBilling}
      />
      <EditModal
        open={editModalOpen}
        billing={billing}
        setBilling={setBilling}
        handleClose={handleEditModalClose}
        handleUpdateBilling={handleUpdateBilling}
      />
    </Box>
  );
};

export default Billing;
