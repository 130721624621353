import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const EditModal = ({
  showEditModal,
  setShowEditModal,
  location,
  setLocation,
  locations,
  setLocations,
  locationID,
}) => {
  const [locationError, setLocationError] = useState(false);
  const [locationErrorMsg, setLocationErrorMsg] = useState("");
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setLocation(value);
    if (value.trim() === "") {
      setLocationError(true);
      setLocationErrorMsg("Location is required");
    } else {
      setLocationError(false);
    }
  };

  const handleModalClose = () => {
    setLocationError(false);
    setLocationErrorMsg("");
    setShowEditModal(false);
  };

  const updateLocation = () => {
    const filteredLocations = locations.filter(
      (loc) => loc._id !== locationID && loc.location === location
    );
    if (location.trim() === "") {
      setLocationError(true);
      setLocationErrorMsg("Location is required");
    } else if (filteredLocations.length > 0) {
      setLocationError(true);
      setLocationErrorMsg("Location already exists");
    } else {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/office-location/${locationID}`, {
          location,
        })
        .then(() => {
          const updatedLocations = locations.map((loc) => ({
            _id: loc._id,
            location: loc._id === locationID ? location : loc.location,
          }));
          setLocations(updatedLocations);
          toast.success("Office location has been updated successfully!");
          setShowEditModal(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  return (
    <Dialog
      open={showEditModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Edit Location</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="location"
          label="Location"
          type="text"
          fullWidth
          variant="standard"
          value={location}
          error={locationError}
          helperText={locationError ? locationErrorMsg : ""}
          onChange={handleLocationChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={updateLocation} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
