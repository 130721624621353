import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import {
  Edit as EditIcon,
  PictureAsPdf as ViewIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  CONSENT_FORM_URL,
  HIPAA_FORM_URL,
  PREGNANCY_WAIVER_FORM_URL,
  ADMIN,
  MEDICAL_ASSISTANT,
} from "../../config";
import { Context } from "../../context";

const Forms = ({ consentForm, hipaaForm, pregnancyWaiverForm }) => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const { patient_id } = useParams();

  return (
    <Box>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        {user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              onClick={() => navigate(`/consent-form/${patient_id}`)}
            >
              Create Consent Form
            </Button>
            {!pregnancyWaiverForm && (
              <Button
                variant="contained"
                onClick={() => navigate(`/pregnancy-waiver-form/${patient_id}`)}
              >
                Create Pregnancy Waiver Form
              </Button>
            )}
          </Stack>
        )}
      </div>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow style={{ background: "grey" }}>
                <TableCell
                  key="no"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Type
                </TableCell>
                <TableCell
                  key="signature"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Signature Status
                </TableCell>
                <TableCell
                  key="action"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consentForm &&
                consentForm.map((form, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{ cursor: "pointer" }}
                    key={index}
                  >
                    <TableCell>Informed Consent Form</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label="patient"
                          color={form.patientSignature ? "success" : "error"}
                          deleteIcon={
                            form.patientSignature ? <DoneIcon /> : <CloseIcon />
                          }
                          onDelete={() => {}}
                        />
                        <Chip
                          label="witness"
                          color={form.witnessSignature ? "success" : "error"}
                          deleteIcon={
                            form.witnessSignature ? <DoneIcon /> : <CloseIcon />
                          }
                          onDelete={() => {}}
                        />
                        <Chip
                          label="provider"
                          color={
                            form.medicalProviderSignature ? "success" : "error"
                          }
                          deleteIcon={
                            form.medicalProviderSignature ? (
                              <DoneIcon />
                            ) : (
                              <CloseIcon />
                            )
                          }
                          onDelete={() => {}}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {user &&
                          (user.role === ADMIN ||
                            user.role === MEDICAL_ASSISTANT) && (
                            <Button
                              color="secondary"
                              variant="contained"
                              endIcon={<EditIcon />}
                              onClick={() =>
                                navigate(`/consent-form/${patient_id}/${index}`)
                              }
                            >
                              Edit
                            </Button>
                          )}
                        <Button
                          color="primary"
                          variant="contained"
                          endIcon={<ViewIcon />}
                          onClick={() =>
                            window.open(
                              `${CONSENT_FORM_URL}/${patient_id + index}.pdf`,
                              "_blank"
                            )
                          }
                        >
                          View
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              {hipaaForm && (
                <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
                  <TableCell>HIPAA Release Form</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="patient"
                        color={hipaaForm.patientSignature ? "success" : "error"}
                        deleteIcon={
                          hipaaForm.patientSignature ? (
                            <DoneIcon />
                          ) : (
                            <CloseIcon />
                          )
                        }
                        onDelete={() => {}}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {user &&
                        (user.role === ADMIN ||
                          user.role === MEDICAL_ASSISTANT) && (
                          <Button
                            color="secondary"
                            variant="contained"
                            endIcon={<EditIcon />}
                            onClick={() =>
                              navigate(`/hipaa-form/${patient_id}`)
                            }
                          >
                            Edit
                          </Button>
                        )}
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ViewIcon />}
                        onClick={() =>
                          window.open(
                            `${HIPAA_FORM_URL}/${patient_id}.pdf`,
                            "_blank"
                          )
                        }
                      >
                        View
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
              {pregnancyWaiverForm && (
                <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
                  <TableCell>Pregnancy Waiver Form</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="patient"
                        color={
                          pregnancyWaiverForm.patientSignature
                            ? "success"
                            : "error"
                        }
                        deleteIcon={
                          pregnancyWaiverForm.patientSignature ? (
                            <DoneIcon />
                          ) : (
                            <CloseIcon />
                          )
                        }
                        onDelete={() => {}}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {user &&
                        (user.role === ADMIN ||
                          user.role === MEDICAL_ASSISTANT) && (
                          <Button
                            color="secondary"
                            variant="contained"
                            endIcon={<EditIcon />}
                            onClick={() =>
                              navigate(`/pregnancy-waiver-form/${patient_id}`)
                            }
                          >
                            Edit
                          </Button>
                        )}
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ViewIcon />}
                        onClick={() =>
                          window.open(
                            `${PREGNANCY_WAIVER_FORM_URL}/${patient_id}.pdf`,
                            "_blank"
                          )
                        }
                      >
                        View
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default Forms;
