import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Stack,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CloudUpload as CloudUploadIcon,
  BackupTable as ExportIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import TopNav from "../components/topnav";
import EditModal from "../components/billing/EditModal";
import DeleteModal from "../components/billing/DeleteModal";
import SummaryModal from "../components/billing/SummaryModal";
import PatientBillingTable from "../components/billing/PatientBillingTable";
import axios from "axios";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import StyledMenu from "../components/StyledMenu";
import { secureApi, API_URL } from "../config";
import { Context } from "../context";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Billing = () => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const [reportFiles, setReportFiles] = useState([]);
  const [loadingBillingInfo, setLoadingBillingInfo] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [billing, setBilling] = useState({});
  const [searchStr, setSearchStr] = useState("");
  const [filterDates, setFilterDates] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleReportSelect = (event) => {
    const files = event.target.files;
    setReportFiles(files);
  };

  const handleReportUpload = () => {
    if (reportFiles.length) {
      setLoadingBillingInfo(true);
      // Create FormData object
      const formData = new FormData();
      for (let i = 0; i < reportFiles.length; i++) {
        formData.append("files", reportFiles[i]);
      }

      axios
        .post(`${API_URL}/upload_billing`, formData, {
          headers: {
            "Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem(
              "alex-med-token"
            )}`,
          },
        })
        .then((response) => {
          const { patients } = response.data;
          setPatients(patients);
          setLoadingBillingInfo(false);
          setReportFiles([]);
          toast.success("Billing information uploaded successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
          setLoadingBillingInfo(false);
        });
    } else {
      toast.warn("Select a billing report file!");
    }
  };

  const handleEditModalOpen = (billing) => {
    setBilling(billing);
    setEditModalOpen(true);
  };

  const handleDeleteModalOpen = (billing) => {
    setBilling(billing);
    setDeleteModalOpen(true);
  };

  const handleSummaryModalOpen = (patient) => {
    setPatient(patient);
    setSummaryModalOpen(true);
  };

  const handleEditModalClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleSummaryModalClose = () => {
    setSummaryModalOpen(false);
  };

  const handleDeleteBilling = (billingID) => {
    const newPatients = [...patients];
    for (let i = 0; i < newPatients.length; i++) {
      if (newPatients[i].billings) {
        // Find and remove the specific billing
        const billingIndex = newPatients[i].billings.findIndex(
          (b) => b._id === billingID
        );
        if (billingIndex !== -1) {
          // Remove the billing using splice
          newPatients[i].billings.splice(billingIndex, 1);
          setPatients(newPatients);
          return;
        }
      }
    }
  };

  const handleUpdateBilling = (billing) => {
    const newPatients = [...patients];
    for (let i = 0; i < newPatients.length; i++) {
      if (newPatients[i].billings) {
        // Find and update the specific billing
        const billingIndex = newPatients[i].billings.findIndex(
          (b) => b._id === billing._id
        );
        if (billingIndex !== -1) {
          // Update the billing at the found index
          newPatients[i].billings[billingIndex] = billing;
          setPatients(newPatients);
          return;
        }
      }
    }
  };

  const downloadEmrBillingReport = () => {
    setAnchorEl(null);

    // Check if there are any patients with billings
    const hasAnyBillings = filteredPatients.some(
      (patient) => patient.billings && patient.billings.length > 0
    );

    if (!hasAnyBillings) {
      toast.warn("No billing data available to export");
      return;
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert data with headers to sheet
    const dataWithHeaders = [];

    // Process each patient's billings
    filteredPatients.forEach((patient) => {
      if (patient.billings) {
        patient.billings.forEach((billing) => {
          const rowData = {
            "Patient ID": billing.identifier || "",
            "Date Of Service": billing.date_of_service || "",
            "First Name": patient.first_name || "",
            "Last Name": patient.last_name || "",
            CPTs: billing.cpt_code || "",
            "DX (ICD Code)": billing.diagnosis_icd_code || "",
            Authorization: patient.authorization || "",
            "Authorization Validity Dates": patient.authorization_validity_dates
              ? `${new Date(
                  patient.authorization_validity_dates.start
                ).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "UTC",
                })} - ${new Date(
                  patient.authorization_validity_dates.end
                ).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "UTC",
                })}`
              : "",
            Provider: "Dr Alex",
            Location: patient.location || "",
            "Chart#": patient.chart || "",
            Insurance: billing.insurance || "",
            "Insurance ID (Policy number)": billing.insurance_id || "",
          };
          dataWithHeaders.push(rowData);
        });
      }
    });

    // Convert data with headers to sheet
    const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Create workbook and add sheet with data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "EMR Billing Report");

    // Write workbook to buffer in xlsx format
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert buffer to Blob with specified MIME type
    const data = new Blob([excelBuffer], { type: fileType });

    // Save data as a file with specified filename
    FileSaver.saveAs(
      data,
      `EMR_Billing_Report_${
        new Date().toISOString().split("T")[0]
      }${fileExtension}`
    );
  };

  const downloadDemographicsReport = () => {
    setAnchorEl(null);

    // Check if there are any patients
    if (!filteredPatients) {
      toast.warn("No available patients to export");
      return;
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert data with headers to sheet
    const dataWithHeaders = [];

    // Process each patient's billings
    filteredPatients.forEach((patient) => {
      const rowData = {
        "Patient ID": patient.billings?.[0]?.identifier || "",
        "First Name": patient.first_name || "",
        "Last Name": patient.last_name || "",
        DOB: patient.date_of_birth || "",
        Gender: patient.gender || "",
        "Home#": patient.contactInfo?.home_phone || "",
        "Cell#": patient.contactInfo?.contact_number || "",
        Email: patient.contactInfo?.email_address || "",
        "Primary Address": patient.contactInfo?.primary_address || "",
        Insurance: patient.billings?.[0]?.insurance || "",
        "Insurance ID (Policy number)":
          patient.billings?.[0]?.insurance_id || "",
        "Primary Insurance Relation": patient.primary_insurance_relation || "",
      };
      dataWithHeaders.push(rowData);
    });

    // Convert data with headers to sheet
    const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Create workbook and add sheet with data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "EMR Billing Report");

    // Write workbook to buffer in xlsx format
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert buffer to Blob with specified MIME type
    const data = new Blob([excelBuffer], { type: fileType });

    // Save data as a file with specified filename
    FileSaver.saveAs(
      data,
      `Demographics_Report_${
        new Date().toISOString().split("T")[0]
      }${fileExtension}`
    );
  };

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get(`/patients_billings`)
      .then((response) => {
        const { patients } = response.data;
        setPatients(patients);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (searchStr || filterDates) {
      const filteredPatients = patients.filter((patient) => {
        // Create a copy of patient with filtered notes
        const filteredPatient = { ...patient };

        if (patient.notes) {
          // Filter notes based on search string and dates
          filteredPatient.notes = patient.notes.filter((note) => {
            // Check if billing matches search string
            const matchesSearch =
              !searchStr ||
              [
                patient.insurance,
                patient.first_name,
                patient.last_name,
                note.cptCodes?.join(" "),
                note.icd10codes?.join(" ")
              ].some(
                (field) =>
                  field &&
                  field.toLowerCase().includes(searchStr.toLowerCase().trim())
              );

            // Check if billing matches date range
            let matchesDates = true;
            if (filterDates && filterDates[0] && filterDates[1]) {
              const startDate = new Date(filterDates[0]);
              const endDate = new Date(filterDates[1]);
              const dateOfService = note.dateOfService || note.dateOfProcedure;
              if (dateOfService) {
                const [month, day, year] = dateOfService.split("/");
                const serviceDate = new Date(year, month - 1, day); // month is 0-based in JS
                matchesDates =
                  serviceDate >= startDate && serviceDate <= endDate;
              } else {
                matchesDates = false;
              }
            }

            return matchesSearch && matchesDates;
          });
        }

        return filteredPatient.notes && filteredPatient.notes.length > 0;
      });

      setFilteredPatients(filteredPatients);
    } else {
      console.log(patients);
      setFilteredPatients(patients);
    }
  }, [searchStr, filterDates, patients]);

  return (
    <>
      <TopNav item="billing" />
      <Box margin="30px">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
              >
                Select File
                <VisuallyHiddenInput
                  type="file"
                  accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleReportSelect}
                  multiple
                />
              </Button>
              <LoadingButton
                size="small"
                onClick={handleReportUpload}
                loading={loadingBillingInfo}
                disabled={!reportFiles.length}
                endIcon={<SendIcon />}
                loadingPosition="end"
                variant="contained"
              >
                Upload Report
              </LoadingButton>
            </Stack>
          </div>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Stack direction="row" style={{ float: "right" }} gap={1}>
              <Button
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={<ExportIcon />}
              >
                Export
              </Button>
              <StyledMenu
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={downloadEmrBillingReport}>
                  EMR Billing Report
                </MenuItem>
                <MenuItem onClick={downloadDemographicsReport}>
                  Demographics Report
                </MenuItem>
              </StyledMenu>
              <DateRangePicker
                format="MM/dd/yyyy"
                character=" - "
                placeholder="Date of Service"
                size="lg"
                value={filterDates}
                onChange={(value) => setFilterDates(value)}
              />
              <TextField
                size="small"
                label="Search"
                variant="outlined"
                style={{ float: "right" }}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
              />
            </Stack>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ background: "#c4f6ff" }}>
                <TableCell />
                <TableCell>Chart #</TableCell>
                <TableCell>Patient</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPatients.length > 0 &&
                filteredPatients.map((patient, index) => (
                  <PatientBillingTable
                    patient={patient}
                    key={index}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    handleEditModalOpen={handleEditModalOpen}
                    handleSummaryModalOpen={handleSummaryModalOpen}
                  />
                ))}
              {filteredPatients.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    No Patient Information
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <DeleteModal
        open={deleteModalOpen}
        billing={billing}
        handleClose={handleDeleteModalClose}
        handleDeleteBilling={handleDeleteBilling}
      />
      <EditModal
        open={editModalOpen}
        billing={billing}
        setBilling={setBilling}
        handleClose={handleEditModalClose}
        handleUpdateBilling={handleUpdateBilling}
      />
      <SummaryModal
        open={summaryModalOpen}
        patient={patient}
        filterDates={filterDates}
        handleClose={handleSummaryModalClose}
      />
    </>
  );
};

export default Billing;
