import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Input,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Save as SaveIcon, SignLanguage } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { MEDICAL_BILLING_PERSON, secureApi } from "../config";
import { Context } from "../context";
import TopNav from "../components/topnav";

const useStyles = makeStyles({
  centeredInput: {
    "& input": {
      textAlign: "center",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  underlineText: {
    textDecoration: "underline",
  },
  mt0: {
    marginTop: "0",
  },
  font18px: {
    fontSize: "18px",
  },
});

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const date = today.getDate();

const PregnancyWaiverForm = () => {
  const classes = useStyles();
  const { patient_id } = useParams();
  const navigate = useNavigate();
  const { setLoggedIn, user } = useContext(Context);

  if (user && user.role === MEDICAL_BILLING_PERSON) {
    toast.warn("You don't have permission to access this page.");
    navigate("/");
  }

  const [patient, setPatient] = useState({});
  const [patientSignature, setPatientSignature] = useState("");
  const [patientSignatureDate, setPatientSignatureDate] = useState("");

  const [signPad, setSignPad] = useState({});
  const [signed, setSigned] = useState(false);
  const [signModalOpen, setSignModalOpen] = useState(false);

  const handleClickPatientSign = () => {
    setSignModalOpen(true);
  };
  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };

  const handleSignModalClose = () => {
    handleClear();
    setSignModalOpen(false);
  };

  const handleSign = () => {
    const physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
    setPatientSignature(physicalSign);
    if (patientSignatureDate.trim() === "")
      setPatientSignatureDate(`${month}/${date}/${year}`);
    handleSignModalClose();
  };

  const handleSave = () => {
    secureApi(localStorage.getItem("alex-med-token"))
      .post(`/pregnancy-waiver-form/${patient_id}`, {
        patientName:
          patient &&
          patient.patientInfo &&
          `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`,
        patientSignature,
        patientSignatureDate,
      })
      .then(() => {
        toast.success("Pregnancy Waiver form has been saved successfully!");
        navigate(`/patient/${patient_id}`);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`patient/${patient_id}`)
        .then((response) => {
          const { data: patient } = response;
          setPatient(patient);
          if (patient && patient.pregnancyWaiverForm) {
            setPatientSignature(patient.pregnancyWaiverForm.patientSignature);
            setPatientSignatureDate(
              patient.pregnancyWaiverForm.patientSignatureDate
            );
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.warn("Invalid patient ID!");
      navigate("/");
    }
  }, [patient_id]);
  return (
    <>
      <TopNav />
      <Stack padding="30px" spacing={2}>
        <Stack color="#808080">
          <Typography
            variant="h5"
            textAlign="center"
            marginBottom="0"
            fontWeight="bold"
          >
            Modal Medical Services
          </Typography>
          <Typography variant="h5" textAlign="center">
            369 LEXINGTON AVE, NEW YORK NE 10017 | NPI: 1316662604 | TIN:
            88-2458377
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography variant="h5" textAlign="center" fontWeight="bold">
              Dr. Alexander Movshis
            </Typography>
            <Typography variant="h5" textAlign="center">
              | NPI: 1942741160
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Pregnancy Waiver Form for Pain Management Procedures
        </Typography>
        <Typography variant="h6" fontWeight="bold" textAlign="center">
          Patient Acknowledgment and Waiver of Pregnancy Test
        </Typography>
        <p style={{ fontSize: "18px" }}>
          I, the undersigned, acknowledge that I have been informed of the
          potential risks and implications of undergoing pain management
          procedures while pregnant. I understand that certain medications,
          injections, procedures, and imaging studies used in these treatments
          may pose risks to an unborn child.
        </p>
        <p style={{ fontSize: "18px" }}>
          I have also been informed of the option to take a pregnancy test prior
          to the administration of any procedure to confirm that I am not
          pregnant. I acknowledge that the pregnancy test is offered to ensure
          the safety of both myself and any potential pregnancy.
        </p>
        <p style={{ fontSize: "18px" }}>
          By signing this waiver, I am voluntarily choosing to decline the
          pregnancy test and affirm that, to the best of my knowledge, I am not
          pregnant at this time. I understand and accept full responsibility for
          any risks or complications that may arise as a result of declining
          this test.
        </p>
        <Typography variant="h6" fontWeight="bold">
          Patient Consent
        </Typography>
        <p style={{ fontSize: "18px" }}>
          I hereby release the practice and its staff from any responsibility or
          liability related to any adverse effects that may occur due to the
          lack of pregnancy confirmation. I acknowledge that I have had the
          opportunity to ask questions regarding the risks of undergoing pain
          management procedures while potentially pregnant, and I understand the
          information provided to me.
        </p>
        <Stack direction="column">
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1} style={{ width: "33%" }}>
              <label
                className={[
                  classes.mt0,
                  classes.boldText,
                  classes.font18px,
                ].join(" ")}
              >
                Signature:
              </label>
              {patientSignature ? (
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid grey",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={patientSignature}
                    alt="patient signature"
                    style={{
                      height: "27px",
                      width: "fit-content",
                      maxWidth: "80%",
                    }}
                  />
                </div>
              ) : (
                <Input
                  className={classes.centeredInput}
                  value="Not signed yet"
                  readOnly
                  fullWidth
                ></Input>
              )}
            </Stack>
            <Stack direction="row" spacing={1} style={{ width: "33%" }}>
              <label
                className={[
                  classes.mt0,
                  classes.boldText,
                  classes.font18px,
                ].join(" ")}
              >
                Date:
              </label>
              <Input
                className={classes.centeredInput}
                value={patientSignatureDate}
                onChange={(e) => setPatientSignatureDate(e.target.value)}
                fullWidth
              ></Input>
            </Stack>
            <Stack direction="row" spacing={1} style={{ width: "34%" }}>
              <label
                className={[
                  classes.mt0,
                  classes.boldText,
                  classes.font18px,
                ].join(" ")}
                style={{ width: "150px" }}
              >
                Patient Name:
              </label>
              <Input
                className={classes.centeredInput}
                value={
                  patient && patient.patientInfo
                    ? `${patient && patient.patientInfo.first_name} ${
                        patient && patient.patientInfo.last_name
                      }`
                    : ""
                }
                readOnly
                fullWidth
              ></Input>
            </Stack>
          </Stack>
        </Stack>
        <Divider style={{ margin: "10px 0" }} />
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SignLanguage />}
            onClick={handleClickPatientSign}
          >
            Patient Sign
          </Button>
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Dialog open={signModalOpen} onClose={handleSignModalClose} maxWidth="lg">
        <DialogTitle>Patient Sign</DialogTitle>
        <Divider />
        <DialogContent style={{ width: "fit-content" }}>
          <div
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              ref={(ref) => {
                setSignPad(ref);
              }}
              onEnd={() => setSigned(true)}
            />
          </div>
        </DialogContent>
        <Divider style={{ margin: "5px 0px" }} />
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClear} variant="contained" color="warning">
            Clear
          </Button>
          <Button onClick={handleSign} variant="contained" disabled={!signed}>
            Sign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PregnancyWaiverForm;
