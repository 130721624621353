import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  facilities,
  setFacilities,
  facilityID,
}) => {
  const {setLoggedIn} = useContext(Context);
  const navigate = useNavigate();
  
  const handleConfirmDelete = async () => {
    try {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/facility/${facilityID}`)
        .then(() => {
          setFacilities(facilities.filter((fac) => fac._id !== facilityID));
          toast.success("Facility has been deleted successfully!");
          setShowDeleteModal(false);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } catch (error) {
      console.error("Error deleting facility:", error);
    }
  };

  return (
    <Dialog
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Delete Facility</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this facility? This action cannot be
        undone.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowDeleteModal(false)}
          color="error"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDelete}
          color="primary"
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
