import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  useMediaQuery,
  TextField,
  InputLabel,
  Select,
  Grid,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const EditModal = ({
  open,
  billing,
  setBilling,
  handleClose,
  handleUpdateBilling,
}) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const updateBilling = () => {
    if (billing && billing["_id"]) {
      // Create a new object without _id
      const { _id, ...billingInfo } = billing;
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/billing/${_id}`, billingInfo)
        .then(() => {
          toast.success("Billing information has been updated successfully!");
          handleUpdateBilling(billing);
          handleClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else toast.warn("Unavailable billing");
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit billing</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label="Insurance"
              value={billing.insurance}
              onChange={(e) =>
                setBilling({ ...billing, insurance: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={billing.status}
                onChange={(e) =>
                  setBilling({ ...billing, status: e.target.value })
                }
                variant="outlined"
                label="Status"
              >
                <MenuItem value="NOT STARTED">Not started</MenuItem>
                <MenuItem value="SUBMITTED">Submitted</MenuItem>
                <MenuItem value="PAID">Paid</MenuItem>
                <MenuItem value="OPEN">Open</MenuItem>
                <MenuItem value="CLOSED">Closed</MenuItem>
                <MenuItem value="MISSING DOCUMENTATION">
                  Missing documentation
                </MenuItem>
                <MenuItem value="APPEALED">Appealed</MenuItem>
                <MenuItem value="RESUBMITTED">Resubmitted</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Service Date"
              value={billing.date_of_service}
              onChange={(e) =>
                setBilling({ ...billing, date_of_service: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Cost share paid"
              value={billing?.cost_share_paid}
              onChange={(e) =>
                setBilling({ ...billing, cost_share_paid: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Date billed"
              value={billing.date_billed}
              onChange={(e) =>
                setBilling({ ...billing, date_billed: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="CPT Code"
              value={billing.cpt_code}
              onChange={(e) =>
                setBilling({ ...billing, cpt_code: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Diagnosis ICD Code"
              value={billing.diagnosis_icd_code}
              onChange={(e) =>
                setBilling({
                  ...billing,
                  diagnosis_icd_code: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Modifiers"
              value={billing.modifiers}
              onChange={(e) =>
                setBilling({
                  ...billing,
                  modifiers: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Charge"
              value={billing.charge}
              onChange={(e) =>
                setBilling({ ...billing, charge: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total Pay"
              value={billing.total_pay}
              onChange={(e) =>
                setBilling({ ...billing, total_pay: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Adjusted Amount"
              value={billing.adjusted_amount}
              onChange={(e) =>
                setBilling({ ...billing, adjusted_amount: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="A/R"
              value={billing.a_r}
              onChange={(e) => setBilling({ ...billing, a_r: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total PR"
              value={billing.tot_pr}
              onChange={(e) =>
                setBilling({ ...billing, tot_pr: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PR Paid"
              value={billing.pr_paid}
              onChange={(e) =>
                setBilling({ ...billing, pr_paid: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PR1"
              value={billing.pr1}
              onChange={(e) => setBilling({ ...billing, pr1: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PR2"
              value={billing.pr2}
              onChange={(e) => setBilling({ ...billing, pr2: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PR3"
              value={billing.pr3}
              onChange={(e) => setBilling({ ...billing, pr3: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PR100"
              value={billing.pr100}
              onChange={(e) =>
                setBilling({ ...billing, pr100: e.target.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateBilling} autoFocus variant="contained">
          Update
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
