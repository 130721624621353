import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Visibility as ViewIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Summarize as SummarizeIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  IconButton,
  Stack,
  ButtonGroup,
  Button,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

const PatientBillingTable = ({
  patient,
  handleEditModalOpen,
  handleDeleteModalOpen,
  handleSummaryModalOpen,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleViewPatient = (patientID) => {
    window.open(`/patient/${patientID}`, "_blank");
  };

  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            background: theme.palette.action.hover,
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {patient["chart"]}
        </TableCell>
        <TableCell>{`${patient["first_name"]} ${patient["last_name"]}`}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<ViewIcon />}
              onClick={() => handleViewPatient(patient["_id"])}
            >
              View Patient
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<SummarizeIcon />}
              onClick={() => handleSummaryModalOpen(patient)}
            >
              Summary
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Billing Information
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ background: "#fce5cd" }}>
                    <TableCell align="right">Insurance</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Service Date</TableCell>
                    <TableCell align="right">Cost share paid</TableCell>
                    <TableCell align="right">Date billed</TableCell>
                    <TableCell align="right">CPT Code</TableCell>
                    <TableCell align="right">Diagnosis ICD Code</TableCell>
                    <TableCell align="right">Modifiers</TableCell>
                    <TableCell align="right">Charge</TableCell>
                    <TableCell align="right">Total Pay</TableCell>
                    <TableCell align="right">Adjusted Amount</TableCell>
                    <TableCell align="right">A/R</TableCell>
                    <TableCell align="right">TOT_PR</TableCell>
                    <TableCell align="right">PR_PAID</TableCell>
                    <TableCell align="right">PR1</TableCell>
                    <TableCell align="right">PR2</TableCell>
                    <TableCell align="right">PR3</TableCell>
                    <TableCell align="right">PR100</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patient.notes &&
                    patient.notes.length > 0 &&
                    patient.notes.map((note, index) => (
                      <TableRow
                        sx={{
                          "& > *": {
                            borderBottom: "unset",
                            background: theme.palette.action.hover,
                          },
                        }}
                        key={index}
                      >
                        <TableCell component="th" scope="row">
                          {patient.insurance}
                        </TableCell>
                        <TableCell align="right">{note.status}</TableCell>
                        <TableCell align="right">
                          {note["dateOfService"] || note["dateOfProcedure"]}
                        </TableCell>
                        <TableCell align="right">
                          {note.cost_share_paid}
                        </TableCell>
                        <TableCell align="right">{note.date_billed}</TableCell>
                        <TableCell align="right">
                          {note.cptCodes
                            ?.map((code) => code.split(" - ")[0])
                            .join(", ")}
                        </TableCell>
                        <TableCell align="right">
                          {note.icd10codes
                            ?.map((code) => code.split(" - ")[0])
                            .join(", ")}
                        </TableCell>
                        <TableCell align="right">{note.modifiers}</TableCell>
                        <TableCell align="right">{note.charge}</TableCell>
                        <TableCell align="right">{note.total_pay}</TableCell>
                        <TableCell align="right">
                          {note.adjusted_amount}
                        </TableCell>
                        <TableCell align="right">{note.a_r}</TableCell>
                        <TableCell align="right">{note.tot_pr}</TableCell>
                        <TableCell align="right">{note.pr_paid}</TableCell>
                        <TableCell align="right">{note.pr1}</TableCell>
                        <TableCell align="right">{note.pr2}</TableCell>
                        <TableCell align="right">{note.pr3}</TableCell>
                        <TableCell align="right">{note.pr100}</TableCell>
                        <TableCell align="right">
                          <ButtonGroup size="small">
                            <IconButton
                              variant="contained"
                              color="warning"
                              onClick={() => handleEditModalOpen(note)}
                            >
                              <EditIcon />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  {(!patient.billings || patient.billings.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={20} sx={{ textAlign: "center" }}>
                        No Billing Information
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PatientBillingTable;
