import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";
const AddModal = ({
  showAddModal,
  setShowAddModal,
  refer,
  setRefer,
  refers,
  setRefers,
}) => {
  const { setLoggedIn } = useContext(Context);
  const [referError, setReferError] = useState(false);
  const [referErrorMsg, setReferErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleModalClose = () => {
    setRefer("");
    setReferError(false);
    setShowAddModal(false);
  };

  const handleReferChange = (e) => {
    const { value } = e.target;
    setRefer(value);
    if (value.trim() === "") {
      setReferError(true);
      setReferErrorMsg("Refer is required");
    } else {
      setReferError(false);
    }
  };

  const addRefer = () => {
    if (refer.trim() === "") {
      setReferError(true);
      setReferErrorMsg("Refer is required");
    } else if (refers.some((ref) => ref.refer === refer)) {
      setReferError(true);
      setReferErrorMsg("Refer already exists");
    } else
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post("/refer", {
          refer,
        })
        .then((response) => {
          setRefers([...refers, response.data]);
          toast.success("Refer has been added successfully!");
          handleModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
  };
  return (
    <Dialog
      open={showAddModal}
      onClose={handleModalClose}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
    >
      <DialogTitle>Add Refer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="refer"
          label="Refer"
          type="text"
          fullWidth
          variant="standard"
          value={refer}
          error={referError}
          helperText={referError ? referErrorMsg : ""}
          onChange={handleReferChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="error">
          Cancel
        </Button>
        <Button onClick={addRefer} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
