import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  IconButton,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileOpen as FileOpenIcon,
  OpenInBrowser as OpenIcon,
  Download as DownloadIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import JSZip from 'jszip';
import {
  secureApi,
  API_URL,
  DEMOGRAPHICS_URL,
  IMAGES_REVIEWED_URL,
  ATTACHMENT_URL,
  MEDICAL_ASSISTANT,
  ADMIN,
} from "../../config";
import { Context } from "../../context";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Attachments = () => {
  const { setLoggedIn, user } = useContext(Context);
  const navigate = useNavigate();
  const { patient_id } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [attachmentID, setAttachmentID] = useState(null);

  const handleAddButtonClick = () => {
    setAttachmentID(null);
    setAttachmentModalOpen(true);
  };

  const handleEditButtonClick = (attachmentID, comment) => {
    setAttachmentID(attachmentID);
    setComment(comment);
    setAttachmentModalOpen(true);
  };

  const handleDeleteButtonClick = (attachmentID) => {
    setAttachmentID(attachmentID);
    setDeleteModalOpen(true);
  };

  const handleAttachmentModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setFile(null);
      setComment("");
      setCommentError(false);
      setAttachmentModalOpen(false);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    if (!event.target.value) setCommentError(true);
    else setCommentError(false);
  };

  const handleSave = () => {
    if (attachmentID) {
      secureApi(localStorage.getItem("alex-med-token"))
        .put(`/attachment/${attachmentID}`, {
          comment: comment,
        })
        .then(() => {
          toast.success("Attachment has been updated successfully!");
          const newAttachments = attachments.map((attachment) => {
            if (attachment._id === attachmentID) {
              return {
                ...attachment,
                comment,
              };
            } else {
              return attachment;
            }
          });
          setAttachments(newAttachments);
          handleAttachmentModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong while updating an attachment.");
          }
        });
    } else if (file) {
      if (!comment) {
        setCommentError(true);
      } else {
        // Create FormData object
        const formData = new FormData();
        formData.append("attachment", file);
        formData.append("comment", comment);
        formData.append("patient_id", patient_id);

        axios
          .post(`${API_URL}/attachment`, formData, {
            headers: {
              "Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              Authorization: `Bearer ${window.localStorage.getItem(
                "alex-med-token"
              )}`,
            },
          })
          .then((response) => {
            const { attachment_id, type, filename, time } = response.data;
            setAttachments([
              ...attachments,
              {
                _id: attachment_id,
                type,
                comment,
                filename,
                time,
              },
            ]);
            handleAttachmentModalClose();
            toast.success("Attachment created successfully.");
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else if (e.response && e.response.data) {
              toast.error(e.response.data.message);
            } else {
              toast.error("Something went wrong while creating an attachment.");
            }
          });
      }
    }
  };

  const deleteAttachment = () => {
    if (user && user.role === MEDICAL_ASSISTANT) {
      toast.error(
        "Medical assistant doesn't have permission to delete attachments."
      );
      return;
    }
    secureApi(localStorage.getItem("alex-med-token"))
      .delete(`/attachment/${attachmentID}`)
      .then(() => {
        const index = attachments.findIndex(
          (attachment) => attachment._id === attachmentID
        );
        if (index !== -1) {
          setAttachments([
            ...attachments.slice(0, index),
            ...attachments.slice(index + 1),
          ]);
        }
        toast.success("Attachment deleted successfully.");
        setDeleteModalOpen(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else if (e.response && e.response.data) {
          toast.error(e.response.data.message);
        } else {
          toast.error("Something went wrong while deleting an attachment.");
        }
      });
  };

  const handleDownload = async (url) => {
    try {
      // Fetch the file with authentication
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('alex-med-token')}`
        }
      });
      
      if (!response.ok) throw new Error('Download failed');
      
      // Get the blob data
      const blob = await response.blob();
      
      // Create blob URL
      const blobUrl = window.URL.createObjectURL(blob);
      
      // Create temporary link
      const link = document.createElement('a');
      link.href = blobUrl;
      
      // Extract filename from URL
      const filename = url.split('/').pop();
      link.setAttribute('download', filename);
      
      // Trigger download
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
      toast.error('Failed to download file');
    }
  };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();
      
      // Show loading toast
      toast.info('Preparing files for download...');
      
      // Download each file and add to zip
      for (const attachment of attachments) {
        if (attachment._id) { // Only download regular attachments
          const response = await fetch(`${ATTACHMENT_URL}/${attachment.filename}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('alex-med-token')}`
            }
          });
          
          if (!response.ok) throw new Error(`Failed to fetch ${attachment.filename}`);
          
          const blob = await response.blob();
          zip.file(attachment.filename, blob);
        }
      }
      
      // Generate zip file
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      
      // Create download link for zip
      const blobUrl = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `attachments_${new Date().toISOString().split('T')[0]}.zip`);
      
      // Trigger download
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      
      toast.success('All files downloaded successfully');
    } catch (error) {
      console.error('Bulk download failed:', error);
      toast.error('Failed to download files');
    }
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`/attachments/${patient_id}`)
        .then((response) => {
          setAttachments(response.data.attachments);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong while getting attachments");
          }
        });
    }
  }, [patient_id]);
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddButtonClick}
        >
          Add New
        </Button>
        {user && user.role === ADMIN && (
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadAll}
            disabled={attachments.length === 0}
          >
            Download All ({attachments.filter(a => a._id).length})
          </Button>
        )}
      </Stack>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow style={{ background: "grey" }}>
                <TableCell
                  key="type"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Type
                </TableCell>
                <TableCell
                  key="comment"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Comment
                </TableCell>
                <TableCell
                  key="time"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Time
                </TableCell>
                <TableCell
                  key="action"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments &&
                attachments.map((attachment, idx) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{ cursor: "pointer" }}
                    key={idx}
                  >
                    <TableCell>{attachment.type}</TableCell>
                    <TableCell>{attachment.comment}</TableCell>
                    <TableCell>{attachment.time}</TableCell>
                    <TableCell>
                      {attachment.comment === "Demographics" && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            endIcon={<OpenIcon />}
                            onClick={() =>
                              window.open(
                                `${DEMOGRAPHICS_URL}/${attachment.filename}`,
                                "_blank"
                              )
                            }
                          >
                            Open
                          </Button>
                        </Stack>
                      )}
                      {attachment.comment === "Images Reviewed" && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            endIcon={<OpenIcon />}
                            onClick={() =>
                              window.open(
                                `${IMAGES_REVIEWED_URL}/${attachment.filename}`,
                                "_blank"
                              )
                            }
                          >
                            Open
                          </Button>
                        </Stack>
                      )}
                      {attachment._id && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            endIcon={<OpenIcon />}
                            onClick={() =>
                              window.open(
                                `${ATTACHMENT_URL}/${attachment.filename}`,
                                "_blank"
                              )
                            }
                          >
                            Open
                          </Button>
                          <Button
                            variant="contained"
                            endIcon={<EditIcon />}
                            color="secondary"
                            onClick={() =>
                              handleEditButtonClick(
                                attachment._id,
                                attachment.comment
                              )
                            }
                          >
                            Edit
                          </Button>
                          {user && user.role === ADMIN && (
                            <Button
                              variant="contained"
                              endIcon={<DownloadIcon />}
                              onClick={() =>
                                handleDownload(
                                  `${ATTACHMENT_URL}/${attachment.filename}`
                                )
                              }
                            >
                              Download
                            </Button>
                          )}
                          {user && user.role !== MEDICAL_ASSISTANT && (
                            <Button
                              variant="contained"
                              endIcon={<DeleteIcon />}
                              color="error"
                              onClick={() =>
                                handleDeleteButtonClick(attachment._id)
                              }
                            >
                              Delete
                            </Button>
                          )}
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

              {attachments.length === 0 && (
                <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    No attachments
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        open={attachmentModalOpen}
        onClose={handleAttachmentModalClose}
        fullWidth
      >
        <DialogTitle>
          {attachmentID ? "Edit comment" : "Add a new attachment"}
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            {attachmentID === null && (
              <Button
                component="label"
                variant="contained"
                startIcon={<FileOpenIcon />}
              >
                Select file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleFileSelect}
                />
              </Button>
            )}
            {file && <Typography>{file.name}</Typography>}
          </Stack>
          <TextField
            error={commentError}
            margin="dense"
            label="Comment"
            type="text"
            fullWidth
            variant="standard"
            value={comment}
            onChange={handleCommentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleAttachmentModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!attachmentID ? !file || !comment : !comment}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteModalOpen} fullWidth maxWidth="md">
        <DialogTitle>Are you sure to delete the attachment?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDeleteModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            If you agree, the attachment will be deleted permanently!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAttachment} autoFocus variant="contained">
            Agree
          </Button>
          <Button
            onClick={handleDeleteModalClose}
            variant="contained"
            color="error"
          >
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Attachments;
