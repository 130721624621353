import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Stack,
  Skeleton,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  Input,
  TextField,
  Checkbox,
  Chip,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  secureApi,
  complaints,
  CHARACTERISTICS,
  HEADACHE_CHARACTERISTICS,
  complaints_values,
  CHRONIC_PAINS,
  CERVICALDYSTONIA_CHARACTERISTICS,
} from "../../config";
import { Context } from "../../context";

const neckPainGeneralDefault = {
  generalAppearance: "NAD",
  deepTouch: "intact bilaterally",
  fromNerveDistribution: "C4",
  toNerveDistribution: "T1",
  bilateralBR_Bicep: "2+",
  hoffman: "negative",
  shoulder: "Full",
  hawkinsSpecialTest: "negative bilaterally",
  emptyCanSpecialTest: "negative bilaterally",
  neersSpecialTest: "negative bilaterally",
  tinnelsSpecialTest: "negative bilaterally",
  cervicalParaspinals:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  levatorScapula:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  trapezius:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  bicipitalTendon: "non-tender bilaterally",
  shoulderAbductionLeft: 5,
  shoulderAbductionRight: 5,
  elbowFlexionLeft: 5,
  elbowFlexionRight: 5,
  wristExtensionLeft: 5,
  wristExtensionRight: 5,
  elbowExtensionLeft: 5,
  elbowExtensionRight: 5,
  handIntrinsicsLeft: 5,
  handIntrinsicsRight: 5,
  firstDorsalInterosseusLeft: 5,
  firstDorsalInterosseusRight: 5,
  abductorPollicusBrevisLeft: 5,
  abductorPollicusBrevisRight: 5,
};

const lowBackPainGeneralDefault = {
  generalAppearance: "NAD",
  deepTouch: "intact bilaterally",
  leftPatella: "2+",
  rightPatella: "2+",
  achilles: "2+",
  babinskiSign: "Negative bilaterally",
  faberSpecialTest: "negative bilaterally",
  fadirSpecialTest: "negative bilaterally",
  erectorSpinae:
    "tender bilaterally with taut muscle bellies reproducing referred pain.",
  iliopsoas:
    "tender bilaterally with taut muscle bellies reproducing referred pain.",
  quadratusLumborum:
    "tender bilaterally with taut muscle bellies reproducing referred pain.",
  greaterTrochanters: "non-tender bilaterally",
  sacroiliacJoint: "non-tender bilaterally",
  hipFlexionLeft: 5,
  hipFlexionRight: 5,
  kneeExtensionLeft: 5,
  kneeExtensionRight: 5,
  ankleDorsiflexionLeft: 5,
  ankleDorsiflexionRight: 5,
  greatToeExtensionLeft: 5,
  greatToeExtensionRight: 5,
  inversionLeft: 5,
  inversionRight: 5,
  eversionLeft: 5,
  eversionRight: 5,
  plantarFlexionLeft: 5,
  plantarFlexionRight: 5,
};

const HPI = ({
  pains,
  setPains,
  selectedPainIndex,
  setSelectedPainIndex,
  selectedPainKey,
  setSelectedPainKey,
  physicalExams,
  setPhysicalExams,
  handlePhysicalExamChange,
  is_telehealth_consult_note,
  gender,
  dateOfBirth,
}) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const [pendingHPI, setPendingHPI] = useState(false);
  const [characteristicsStatus, setCharacteristicsStatus] = useState(false);

  const addPain = (painKey) => {
    // Not custom pain and the painKey already exists
    if (
      painKey !== "customPain" &&
      pains.map((pain) => pain["key"]).indexOf(painKey) !== -1
    ) {
      toast.warn("That pain already selected!");
    } else {
      let characteristics = {
        specifyMedication: "",
        specifyHeatOrColdApplicationEffect: "",
        specifyLocalizedArea: "",
        specifyUnderstanding: "",
      };
      if (painKey === "headache") {
        characteristics = {
          specifyOnset: "",
          specifyHeadacheDaysPerMonth: "",
          specifyHeadacheHoursPerDay: "",
          specifyDisability: "",
          associatedSymptoms: {
            nausea: false,
            vomiting: false,
            photophobia: false,
            phonophobia: false,
            unilateral: false,
            pulsating: false,
          },
          reviewOfSystems: {
            fever: false,
            chills: false,
            visualDisturbances: false,
            neckStiffness: false,
            numbnessTingling: false,
            dizziness: false,
            changesInBowel: false,
            motorWeakness: false,
            speechDifficulties: false,
          },
        };
      } else if (painKey === "cervicalDystonia") {
        characteristics = {
          triedTreatments: "",
          region: {
            sternocleidomastoid: false,
            trapezius: false,
            levatorScapulae: false,
            scalene: false,
            posteriorCervicalMuscles: false,
          },
          reviewOfSystems: {
            fever: false,
            chills: false,
            numbnessOrTingling: false,
            bowelOrBladderDysfunction: false,
            motorWeakness: false,
          },
        };
      } else if (painKey === "neckPain" || painKey === "backPain") {
        characteristics = "";
      }
      setSelectedPainKey(painKey);
      setSelectedPainIndex(pains.length);
      setPains([
        ...pains,
        {
          key: painKey,
          name: CHRONIC_PAINS[painKey],
          characteristics,
          hpi: "",
        },
      ]);

      switch (painKey) {
        case "headache":
          setPhysicalExams([
            ...physicalExams,
            {
              scalpTenderness: "Absent",
              sinusTenderness: "Absent",
              temporalArteryTenderness: "Absent",
              pupillaryResponse: "PERRLA",
              extraocularMovements: "Intact",
              temporomandibularJoint: "No tenderness",
              neckMuscles: "No tenderness",
              mentalStatus: "Alert",
              cranialNerves: "All cranial nerves intact",
              motorExamination: "No focal weakness",
              sensoryExamination: "Normal sensation",
              reflexes: "Normal reflexes",
              coordination: "Normal coordination",
              rangeOfMotion: "FROM",
              meningealSigns: "Negative",
              cervicalSpineTenderness: "No tenderness",
            },
          ]);
          break;
        case "neckPain":
          setPhysicalExams([
            ...physicalExams,
            {
              ...neckPainGeneralDefault,
              lightTouch: "intact bilaterally",
              leftTricep: "2+",
              rightTricep: "2+",
              cervical: {
                full: true,
                limitedFlexion: false,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              kempsSpecialTest: "negative bilaterally",
              spurlingsSpecialTest: "negative bilaterally",
              shoulderExternalRotationLeft: 4,
              shoulderExternalRotationRight: 4,
            },
          ]);
          break;
        case "cervicalDystonia":
          setPhysicalExams([
            ...physicalExams,
            {
              generalAppearance: "NAD",
              lightTouch: "intact bilaterally",
              deepTouch: "intact bilaterally",
              fromNerveDistribution: "C4",
              toNerveDistribution: "T1",
              leftTricep: "2+",
              rightTricep: "2+",
              bilateralBR_Bicep: "2+",
              hoffman: "negative",
              cervical: {
                full: true,
                limitedFlexion: false,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              shoulder: "Full",
              headPosturing: {
                normal: true,
                torticollis: false,
                laterocollis: false,
                anterocollis: false,
                retrocollis: false,
                sagittalShift: false,
                lateralShift: false,
              },
              kempsSpecialTest: "negative bilaterally",
              hawkinsSpecialTest: "negative bilaterally",
              emptyCanSpecialTest: "negative bilaterally",
              neersSpecialTest: "negative bilaterally",
              tinnelsSpecialTest: "negative bilaterally",
              spurlingsSpecialTest: "negative bilaterally",
              cervicalParaspinals:
                "tender bilaterally with taut muscle bellies reproducing referred pain",
              levatorScapula:
                "tender bilaterally  with taut muscle bellies reproducing referred pain",
              trapezius:
                "tender bilaterally with taut muscle bellies reproducing referred pain",
              bicipitalTendon: "non-tender bilaterally",
              shoulderAbductionLeft: 5,
              shoulderAbductionRight: 5,
              elbowFlexionLeft: 5,
              elbowFlexionRight: 5,
              wristExtensionLeft: 5,
              wristExtensionRight: 5,
              elbowExtensionLeft: 5,
              elbowExtensionRight: 5,
              handIntrinsicsLeft: 5,
              handIntrinsicsRight: 5,
              firstDorsalInterosseusLeft: 5,
              firstDorsalInterosseusRight: 5,
              abductorPollicusBrevisLeft: 5,
              abductorPollicusBrevisRight: 5,
              shoulderExternalRotationLeft: 4,
              shoulderExternalRotationRight: 4,
            },
          ]);
          break;
        case "backPain":
          setPhysicalExams([
            ...physicalExams,
            {
              generalAppearance: "NAD",
              gait: "antalgic",
              lightTouch: "intact bilaterally",
              deepTouch: "intact bilaterally",
              fromNerveDistribution: "L2",
              toNerveDistribution: "S1",
              leftPatella: "2+",
              rightPatella: "2+",
              achilles: "2+",
              babinskiSign: "Negative bilaterally",
              lumbar: {
                full: false,
                limitedFlexion: true,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              slrSpecialTest: "negative bilaterally",
              kempsSpecialTest: "negative bilaterally",
              faberSpecialTest: "negative bilaterally",
              fadirSpecialTest: "negative bilaterally",
              erectorSpinae:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              iliopsoas:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              quadratusLumborum:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              greaterTrochanters: "non-tender bilaterally",
              sacroiliacJoint: "non-tender bilaterally",
              hipFlexionLeft: 5,
              hipFlexionRight: 5,
              kneeExtensionLeft: 5,
              kneeExtensionRight: 5,
              ankleDorsiflexionLeft: 5,
              ankleDorsiflexionRight: 5,
              greatToeExtensionLeft: 5,
              greatToeExtensionRight: 5,
              inversionLeft: 5,
              inversionRight: 5,
              eversionLeft: 5,
              eversionRight: 5,
              hipAbductionLeft: 4,
              hipAbductionRight: 4,
              hipExtensionLeft: 4,
              hipExtensionRight: 4,
              plantarFlexionLeft: 5,
              plantarFlexionRight: 5,
            },
          ]);
          break;
        case "shoulderPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right shoulder",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              deltoidMuscle: "Normal strength",
              rotatorCuffMuscles: "Normal strength",
              impingementSigns: "Positive Neer's sign",
              labralTears: "Positive O'Brien's test",
              instability: "Positive apprehension sign",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "elbowPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right elbow",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              bicepsMuscle: "Normal strength",
              tricepsMuscle: "Normal strength",
              wristExtensors: "Normal strength",
              epicondylitis: "Positive Cozen's test",
              nerveEntrapment: "Positive Tinel's sign at elbow",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "wristPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right wrist",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              gripStrength: "Normal strength",
              wristFlexors: "Normal strength",
              wristExtensors: "Normal strength",
              deQuervainsTenosynovitis: "Positive Finkelstein's test",
              carpalTunnelSyndrome: "Positive Tinel's sign at wrist",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "kneePain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right knee",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              quadricepsMuscle: "Normal strength",
              hamstringsMuscle: "Normal strength",
              meniscalInjury: "Positive McMurray's test",
              ligamentousInjury: "Positive Lachman test",
              sensation: "Intact",
              pulses: "Intact popliteal pulse",
            },
          ]);
          break;
        default:
          setPhysicalExams([...physicalExams, ""]);
      }
    }
  };

  const selectPain = (painIndex) => {
    setSelectedPainIndex(painIndex);
    setSelectedPainKey(pains[painIndex]["key"]);
  };

  const removePain = (index) => {
    const newPains = [...pains];
    newPains.splice(index, 1);
    setPains(newPains);
    setSelectedPainIndex(0);
    if (newPains.length) {
      setSelectedPainKey(newPains[0]["key"]);
    } else {
      setSelectedPainKey(null);
    }

    const newPhysicalExams = [...physicalExams];
    newPhysicalExams.splice(index, 1);
    setPhysicalExams(newPhysicalExams);
  };

  const handleCustomPainNameChange = (newName) => {
    const newPains = [...pains];
    newPains[selectedPainIndex] = {
      ...pains[selectedPainIndex],
      name: newName,
    };
    setPains(newPains);
  };

  const handleChangeHPI = (hpi) => {
    const newPains = [...pains];
    newPains[selectedPainIndex]["hpi"] = hpi;
    setPains(newPains);
  };

  const handleChangeComplaint = (event) => {
    let { value: chiefComplaint } = event.target;
    const newPains = [...pains];
    newPains[selectedPainIndex]["characteristics"] = chiefComplaint;
    newPains[selectedPainIndex]["hpi"] =
      complaints_values[complaints.indexOf(chiefComplaint)];

    setPains(newPains);

    switch (complaints.indexOf(chiefComplaint)) {
      // Neck + Left Arm
      case 0:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "decreased on the left",
          leftTricep: "1+",
          rightTricep: "2+",
          cervical: {
            full: true,
            limitedFlexion: false,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "negative bilaterally",
          spurlingsSpecialTest: "positive on the left",
          shoulderExternalRotationLeft: 4,
          shoulderExternalRotationRight: 5,
        });
        break;

      // Neck + Right Arm
      case 1:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "decreased on the right",
          leftTricep: "2+",
          rightTricep: "1+",
          cervical: {
            full: true,
            limitedFlexion: false,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "negative bilaterally",
          spurlingsSpecialTest: "positive on the right",
          shoulderExternalRotationLeft: 5,
          shoulderExternalRotationRight: 4,
        });
        break;

      // Neck + Bilateral Arms
      case 2:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "decreased bilaterally",
          leftTricep: "1+",
          rightTricep: "1+",
          cervical: {
            full: true,
            limitedFlexion: false,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "negative bilaterally",
          spurlingsSpecialTest: "positive on the right",
          shoulderExternalRotationLeft: 4,
          shoulderExternalRotationRight: 4,
        });
        break;

      // Neck (left)
      case 3:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "intact bilaterally",
          leftTricep: "2+",
          rightTricep: "2+",
          cervical: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "positive on the left",
          spurlingsSpecialTest: "negative bilaterally",
          shoulderExternalRotationLeft: 4,
          shoulderExternalRotationRight: 4,
        });
        break;

      // Neck (right)
      case 4:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "intact bilaterally",
          leftTricep: "2+",
          rightTricep: "2+",
          cervical: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "positive on the right",
          spurlingsSpecialTest: "negative bilaterally",
          shoulderExternalRotationLeft: 4,
          shoulderExternalRotationRight: 4,
        });
        break;

      // Neck (bilateral)
      case 5:
        handlePhysicalExamChange({
          ...neckPainGeneralDefault,
          lightTouch: "intact bilaterally",
          leftTricep: "2+",
          rightTricep: "2+",
          cervical: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          kempsSpecialTest: "positive bilaterally",
          spurlingsSpecialTest: "negative bilaterally",
          shoulderExternalRotationLeft: 4,
          shoulderExternalRotationRight: 4,
        });
        break;

      // Low Back + Left Leg
      case 6:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          gait: "antalgic",
          lightTouch: "decreased on the left",
          lumbar: {
            full: false,
            limitedFlexion: true,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          slrSpecialTest: "positive on the left",
          kempsSpecialTest: "negative bilaterally",
          hipAbductionLeft: 4,
          hipAbductionRight: 5,
          hipExtensionLeft: 5,
          hipExtensionRight: 4,
        });
        break;

      // Low Back + Right Leg
      case 7:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          lightTouch: "decreased on the right",
          gait: "antalgic",
          lumbar: {
            full: false,
            limitedFlexion: true,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          slrSpecialTest: "positive on the right",
          kempsSpecialTest: "negative bilaterally",
          hipAbductionLeft: 5,
          hipAbductionRight: 4,
          hipExtensionLeft: 4,
          hipExtensionRight: 5,
        });
        break;

      // Low Back + Bilateral Legs
      case 8:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          lightTouch: "decreased bilaterally",
          gait: "antalgic",
          lumbar: {
            full: false,
            limitedFlexion: true,
            limitedExtension: false,
            limitedRotation: false,
            limitedLateralBending: false,
          },
          slrSpecialTest: "positive bilaterally",
          kempsSpecialTest: "negative bilaterally",
          hipAbductionLeft: 4,
          hipAbductionRight: 4,
          hipExtensionLeft: 4,
          hipExtensionRight: 4,
        });
        break;

      // Low Back (left)
      case 9:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          lightTouch: "intact bilaterally",
          gait: "Non-antalgic",
          lumbar: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          slrSpecialTest: "negative bilaterally",
          kempsSpecialTest: "positive on the left",
          hipAbductionLeft: 4,
          hipAbductionRight: 4,
          hipExtensionLeft: 4,
          hipExtensionRight: 4,
        });
        break;

      // Low Back (right)
      case 10:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          lightTouch: "intact bilaterally",
          gait: "Non-antalgic",
          lumbar: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          slrSpecialTest: "negative bilaterally",
          kempsSpecialTest: "positive on the right",
          hipAbductionLeft: 4,
          hipAbductionRight: 4,
          hipExtensionLeft: 4,
          hipExtensionRight: 4,
        });
        break;

      // Low (bilateral)
      case 11:
        handlePhysicalExamChange({
          ...lowBackPainGeneralDefault,
          lightTouch: "intact bilaterally",
          gait: "Non-antalgic",
          lumbar: {
            full: false,
            limitedFlexion: false,
            limitedExtension: true,
            limitedRotation: true,
            limitedLateralBending: false,
          },
          slrSpecialTest: "negative bilaterally",
          kempsSpecialTest: "positive bilaterally",
          hipAbductionLeft: 4,
          hipAbductionRight: 4,
          hipExtensionLeft: 4,
          hipExtensionRight: 4,
        });
        break;
    }
  };

  const handleCharacteristicsChange = (key, value) => {
    const newPains = [...pains];
    newPains[selectedPainIndex]["characteristics"][key] = value;
    setPains(newPains);
  };

  const handleSubmit = () => {
    const selectedPain = pains[selectedPainIndex];
    if (selectedPain) {
      const { name: pain, characteristics } = pains[selectedPainIndex];

      const url =
        selectedPainKey === "headache"
          ? "/get_headache_hpi"
          : selectedPainKey === "cervicalDystonia"
          ? "/get_cervical_dystonia_hpi"
          : "/get_hpi";

      setPendingHPI(true);
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(url, { pain, characteristics, gender, dateOfBirth })
        .then((response) => {
          const newPains = [...pains];
          newPains[selectedPainIndex]["hpi"] = response.data;
          setPains(newPains);
          setPendingHPI(false);
        })
        .catch((e) => {
          setPendingHPI(false);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong while getting HPI information.");
          }
        });
    } else {
      toast.error("An error occured with the selected pain!");
    }
  };

  useEffect(() => {
    if (
      selectedPainKey &&
      selectedPainKey !== "backPain" &&
      selectedPainKey !== "neckPain" &&
      selectedPainKey !== "headache" &&
      selectedPainKey !== "cervicalDystonia"
    ) {
      let onsetStatus = pains[selectedPainIndex]["characteristics"]["onset"];
      let provocationPalliationStatus =
        pains[selectedPainIndex]["characteristics"]["provocationPalliation"];
      if (
        provocationPalliationStatus === "Medication provides relief" &&
        !pains[selectedPainIndex]["characteristics"]["specifyMedication"]
      ) {
        setCharacteristicsStatus(false);
        return;
      } else if (
        provocationPalliationStatus === "Heat or cold application affects it" &&
        !pains[selectedPainIndex]["characteristics"][
          "specifyHeatOrColdApplicationEffect"
        ]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let qualityStatus =
        pains[selectedPainIndex]["characteristics"]["quality"];
      let regionRadiationStatus =
        pains[selectedPainIndex]["characteristics"]["regionRadiation"];
      if (
        regionRadiationStatus === "Localized to one specific area" &&
        !pains[selectedPainIndex]["characteristics"]["specifyLocalizedArea"]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let severityStatus =
        pains[selectedPainIndex]["characteristics"]["severity"];
      let timingStatus = pains[selectedPainIndex]["characteristics"]["timing"];
      let understandingStatus =
        pains[selectedPainIndex]["characteristics"]["understanding"];
      if (
        understandingStatus === "Other reason" &&
        !pains[selectedPainIndex]["characteristics"]["specifyUnderstanding"]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      setCharacteristicsStatus(
        onsetStatus &&
          provocationPalliationStatus &&
          qualityStatus &&
          regionRadiationStatus &&
          severityStatus &&
          timingStatus &&
          understandingStatus
      );
    } else if (selectedPainKey === "headache") {
      let onsetStatus = pains[selectedPainIndex]["characteristics"]["onset"];
      if (
        onsetStatus === "Over 12 months" &&
        !pains[selectedPainIndex]["characteristics"]["specifyOnset"]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let headacheDaysPerMonthStatus =
        pains[selectedPainIndex]["characteristics"]["headacheDaysPerMonth"];
      if (
        headacheDaysPerMonthStatus === "Other" &&
        !pains[selectedPainIndex]["characteristics"][
          "specifyHeadacheDaysPerMonth"
        ]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let headacheHoursPerDayStatus =
        pains[selectedPainIndex]["characteristics"]["headacheHoursPerDay"];
      if (
        headacheHoursPerDayStatus === "Other" &&
        !pains[selectedPainIndex]["characteristics"][
          "specifyHeadacheHoursPerDay"
        ]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let disabilityStatus =
        pains[selectedPainIndex]["characteristics"]["disability"];
      if (
        disabilityStatus === "Other" &&
        !pains[selectedPainIndex]["characteristics"]["specifyDisability"]
      ) {
        setCharacteristicsStatus(false);
        return;
      }
      let intensityStatus =
        pains[selectedPainIndex]["characteristics"]["intensity"];
      let erVisitsStatus =
        pains[selectedPainIndex]["characteristics"]["erVisits"];

      setCharacteristicsStatus(
        onsetStatus &&
          headacheDaysPerMonthStatus &&
          headacheHoursPerDayStatus &&
          disabilityStatus &&
          intensityStatus &&
          erVisitsStatus
      );
    } else if (selectedPainKey === "cervicalDystonia") {
      let onsetStatus = pains[selectedPainIndex]["characteristics"]["onset"];
      let severityStatus =
        pains[selectedPainIndex]["characteristics"]["severity"];
      let muscleTightnessStatus =
        pains[selectedPainIndex]["characteristics"]["muscleTightness"];
      let headPosturingIssuesStatus =
        pains[selectedPainIndex]["characteristics"]["headPosturingIssues"];
      let triedTreatmentsStatus =
        pains[selectedPainIndex]["characteristics"]["triedTreatments"];

      setCharacteristicsStatus(
        onsetStatus &&
          severityStatus &&
          muscleTightnessStatus &&
          headPosturingIssuesStatus &&
          triedTreatmentsStatus
      );
    }
  }, [selectedPainKey, selectedPainIndex, pains]);

  return (
    <Stack spacing={3}>
      <Stack direction="row">
        <FormGroup
          style={{ width: "200px", minWidth: "200px", marginRight: "10px" }}
        >
          <FormControl>
            <RadioGroup>
              {Object.keys(CHRONIC_PAINS).map((pain, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => addPain(pain)}
                >
                  {CHRONIC_PAINS[pain]}
                </Button>
              ))}
            </RadioGroup>
            {selectedPainKey === "customPain" && (
              <Input
                placeholder="Pain"
                variant="outlined"
                style={{ margin: "10px 0" }}
                value={pains[selectedPainIndex]["name"]}
                onChange={(e) => handleCustomPainNameChange(e.target.value)}
              />
            )}
          </FormControl>
        </FormGroup>

        <Stack style={{ width: "-webkit-fill-available" }} spacing={2}>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            Modal Medical Services PLLC
          </Typography>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Pain Management Service
          </Typography>
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: "bold",
              marginTop: "20px",
              textDecoration: "underline",
            }}
          >
            Initial Evaluation Form
          </Typography>
          {is_telehealth_consult_note && (
            <Typography
              variant="h5"
              align="center"
              style={{
                fontWeight: "bold",
                marginTop: "20px",
                textDecoration: "underline",
              }}
            >
              Telehealth Visit
            </Typography>
          )}

          <Stack direction="row" spacing={1}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              Chief Complaint:
            </Typography>
            <Typography variant="h6">
              {pains.map((pain) => pain["name"]).join(", ")}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            display="inline-block"
            lineHeight={2.5}
          >
            {pains &&
              pains.map((pain, index) => (
                <Chip
                  color={selectedPainIndex === index ? "success" : "primary"}
                  key={index}
                  label={pain["name"]}
                  onDelete={() => removePain(index)}
                  onClick={() => {
                    selectPain(index);
                  }}
                />
              ))}
          </Stack>

          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              marginTop: "10px",
            }}
          >
            History of Present Illness:
          </Typography>

          {pendingHPI ? (
            <Skeleton height={150} animation="wave" />
          ) : (
            <TextField
              multiline
              sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
              value={pains.length ? pains[selectedPainIndex]?.hpi : ""}
              onChange={(e) => handleChangeHPI(e.target.value)}
              fullWidth
              rows={10}
            />
          )}
        </Stack>
      </Stack>
      {selectedPainKey === "neckPain" && (
        <FormGroup
          style={{ width: "250px", minWidth: "250px", marginRight: "10px" }}
        >
          {complaints.map(
            (item, index) =>
              index < 6 && (
                <FormControlLabel
                  value={item}
                  key={index}
                  control={<Radio />}
                  label={item}
                  onChange={handleChangeComplaint}
                  checked={pains[selectedPainIndex]["characteristics"] === item}
                />
              )
          )}
        </FormGroup>
      )}
      {selectedPainKey === "backPain" && (
        <FormGroup
          style={{ width: "250px", minWidth: "250px", marginRight: "10px" }}
        >
          {complaints.map(
            (item, index) =>
              index > 5 && (
                <FormControlLabel
                  value={item}
                  key={index}
                  control={<Radio />}
                  label={item}
                  onChange={handleChangeComplaint}
                  checked={pains[selectedPainIndex]["characteristics"] === item}
                />
              )
          )}
        </FormGroup>
      )}
      {selectedPainKey === "headache" && (
        <Stack direction="row" spacing={2} justifyContent="space-around">
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">Headache Onset</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["onset"].map((item, index) => (
                  <React.Fragment key={index}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "onset"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("onset", item)
                          }
                        />
                      }
                      key={index}
                      label={item}
                      value={item}
                    />
                    {index === 2 && (
                      <Input
                        placeholder="Specify"
                        disabled={
                          pains[selectedPainIndex]["characteristics"][
                            "onset"
                          ] !== "Over 12 months"
                        }
                        value={
                          pains[selectedPainIndex]["characteristics"][
                            "specifyOnset"
                          ]
                        }
                        onChange={(e) =>
                          handleCharacteristicsChange(
                            "specifyOnset",
                            e.target.value
                          )
                        }
                      ></Input>
                    )}
                  </React.Fragment>
                ))}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Headache days per month</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["headacheDaysPerMonth"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "headacheDaysPerMonth"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange(
                                "headacheDaysPerMonth",
                                item
                              )
                            }
                          />
                        }
                        label={item}
                        value={item}
                      />
                      {index === 2 && (
                        <Input
                          placeholder="Specify"
                          disabled={
                            pains[selectedPainIndex]["characteristics"][
                              "headacheDaysPerMonth"
                            ] !== "Other"
                          }
                          value={
                            pains[selectedPainIndex]["characteristics"][
                              "specifyHeadacheDaysPerMonth"
                            ]
                          }
                          onChange={(e) =>
                            handleCharacteristicsChange(
                              "specifyHeadacheDaysPerMonth",
                              e.target.value
                            )
                          }
                        ></Input>
                      )}
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">
                Number of headache hours per day
              </Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["headacheHoursPerDay"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "headacheHoursPerDay"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange(
                                "headacheHoursPerDay",
                                item
                              )
                            }
                          />
                        }
                        key={index}
                        label={item}
                        value={item}
                      />
                      {index === 2 && (
                        <Input
                          placeholder="Specify"
                          disabled={
                            pains[selectedPainIndex]["characteristics"][
                              "headacheHoursPerDay"
                            ] !== "Other"
                          }
                          value={
                            pains[selectedPainIndex]["characteristics"][
                              "specifyHeadacheHoursPerDay"
                            ]
                          }
                          onChange={(e) =>
                            handleCharacteristicsChange(
                              "specifyHeadacheHoursPerDay",
                              e.target.value
                            )
                          }
                        ></Input>
                      )}
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">Disability due to headache</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["disability"].map((item, index) => (
                  <React.Fragment key={index}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "disability"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("disability", item)
                          }
                        />
                      }
                      label={item}
                      value={item}
                    />
                    {index === 2 && (
                      <Input
                        placeholder="Specify"
                        disabled={
                          pains[selectedPainIndex]["characteristics"][
                            "disability"
                          ] !== "Other"
                        }
                        value={
                          pains[selectedPainIndex]["characteristics"][
                            "specifyDisability"
                          ]
                        }
                        onChange={(e) =>
                          handleCharacteristicsChange(
                            "specifyDisability",
                            e.target.value
                          )
                        }
                      ></Input>
                    )}
                  </React.Fragment>
                ))}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Headache Intensity</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["intensity"].map((item, index) => (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          pains[selectedPainIndex]["characteristics"][
                            "intensity"
                          ] === item
                        }
                        onClick={() =>
                          handleCharacteristicsChange("intensity", item)
                        }
                      />
                    }
                    key={index}
                    label={item}
                    value={item}
                  />
                ))}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Associated Symptoms</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["associatedSymptoms"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "associatedSymptoms"
                              ][item.toLowerCase()]
                            }
                            onClick={(e) => {
                              let newAssociatedSymptoms = {
                                ...pains[selectedPainIndex]["characteristics"][
                                  "associatedSymptoms"
                                ],
                              };
                              newAssociatedSymptoms[item.toLowerCase()] =
                                e.target.checked;
                              handleCharacteristicsChange(
                                "associatedSymptoms",
                                newAssociatedSymptoms
                              );
                            }}
                          />
                        }
                        key={index}
                        label={item}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">ER visits due to headache</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["erVisits"].map((item, index) => (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          pains[selectedPainIndex]["characteristics"][
                            "erVisits"
                          ] === item
                        }
                        onClick={() =>
                          handleCharacteristicsChange("erVisits", item)
                        }
                      />
                    }
                    key={index}
                    label={item}
                    value={item}
                  />
                ))}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Review of systems</Typography>
              <FormGroup>
                {HEADACHE_CHARACTERISTICS["reviewOfSystems"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "reviewOfSystems"
                              ][item["key"]]
                            }
                            onClick={(e) => {
                              let newReviewOfSystems = {
                                ...pains[selectedPainIndex]["characteristics"][
                                  "reviewOfSystems"
                                ],
                              };
                              newReviewOfSystems[item["key"]] =
                                e.target.checked;
                              handleCharacteristicsChange(
                                "reviewOfSystems",
                                newReviewOfSystems
                              );
                            }}
                          />
                        }
                        label={item["label"]}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={pendingHPI}
                disabled={!characteristicsStatus}
                endIcon={<SendIcon />}
                loadingPosition="end"
                onClick={handleSubmit}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      {selectedPainKey === "cervicalDystonia" && (
        <Stack direction="row" spacing={2} justifyContent="space-around">
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">Onset</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["onset"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "onset"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange("onset", item)
                            }
                          />
                        }
                        label={item}
                        value={item}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Severity</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["severity"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "severity"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange("severity", item)
                            }
                          />
                        }
                        label={item}
                        value={item}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Muscle Tightness</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["muscleTightness"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "muscleTightness"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange(
                                "muscleTightness",
                                item
                              )
                            }
                          />
                        }
                        key={index}
                        label={item}
                        value={item}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">Head/Posturing Issues</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["headPosturingIssues"].map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "headPosturingIssues"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange(
                                "headPosturingIssues",
                                item
                              )
                            }
                          />
                        }
                        label={item}
                        value={item}
                      />
                    </React.Fragment>
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Region</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["region"].map(
                  (item, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "region"
                            ][item["key"]]
                          }
                          onClick={(e) => {
                            let newRegion = {
                              ...pains[selectedPainIndex]["characteristics"][
                                "region"
                              ],
                            };
                            newRegion[item["key"]] = e.target.checked;
                            handleCharacteristicsChange("region", newRegion);
                          }}
                        />
                      }
                      key={index}
                      label={item["label"]}
                    />
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <Typography variant="h6">Treatments tried</Typography>
              <FormGroup>
                <Input
                  placeholder="Specify"
                  value={
                    pains[selectedPainIndex]["characteristics"][
                      "triedTreatments"
                    ]
                  }
                  onChange={(e) =>
                    handleCharacteristicsChange(
                      "triedTreatments",
                      e.target.value
                    )
                  }
                ></Input>
              </FormGroup>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography variant="h6">Review of systems</Typography>
              <FormGroup>
                {CERVICALDYSTONIA_CHARACTERISTICS["reviewOfSystems"].map(
                  (item, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "reviewOfSystems"
                            ][item["key"]]
                          }
                          onClick={(e) => {
                            let newReviewOfSystems = {
                              ...pains[selectedPainIndex]["characteristics"][
                                "reviewOfSystems"
                              ],
                            };
                            newReviewOfSystems[item["key"]] = e.target.checked;
                            handleCharacteristicsChange(
                              "reviewOfSystems",
                              newReviewOfSystems
                            );
                          }}
                        />
                      }
                      key={index}
                      label={item["label"]}
                    />
                  )
                )}
              </FormGroup>
            </Stack>
            <Stack>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={pendingHPI}
                disabled={!characteristicsStatus}
                endIcon={<SendIcon />}
                loadingPosition="end"
                onClick={handleSubmit}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      {selectedPainKey &&
        selectedPainKey !== "neckPain" &&
        selectedPainKey !== "backPain" &&
        selectedPainKey !== "headache" &&
        selectedPainKey !== "cervicalDystonia" && (
          <Stack direction="row" spacing={2} justifyContent="space-around">
            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography variant="h6">Onset</Typography>
                <FormGroup>
                  {CHARACTERISTICS["onset"].map((item, index) => (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "onset"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("onset", item)
                          }
                        />
                      }
                      key={index}
                      label={item}
                      value={item}
                    />
                  ))}
                </FormGroup>
              </Stack>
              <Stack>
                <Typography variant="h6">Provocation/Palliation</Typography>
                <FormGroup>
                  {CHARACTERISTICS["provocationPalliation"].map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                pains[selectedPainIndex]["characteristics"][
                                  "provocationPalliation"
                                ] === item
                              }
                              onClick={() =>
                                handleCharacteristicsChange(
                                  "provocationPalliation",
                                  item
                                )
                              }
                            />
                          }
                          label={item}
                          value={item}
                        />
                        {index === 2 && (
                          <Input
                            placeholder="Specify medication"
                            disabled={
                              pains[selectedPainIndex]["characteristics"][
                                "provocationPalliation"
                              ] !== "Medication provides relief"
                            }
                            value={
                              pains[selectedPainIndex]["characteristics"][
                                "specifyMedication"
                              ]
                            }
                            onChange={(e) =>
                              handleCharacteristicsChange(
                                "specifyMedication",
                                e.target.value
                              )
                            }
                          ></Input>
                        )}
                        {index === 3 && (
                          <Input
                            placeholder="Specify how"
                            disabled={
                              pains[selectedPainIndex]["characteristics"][
                                "provocationPalliation"
                              ] !== "Heat or cold application affects it"
                            }
                            value={
                              pains[selectedPainIndex]["characteristics"][
                                "specifyHeatOrColdApplicationEffect"
                              ]
                            }
                            onChange={(e) =>
                              handleCharacteristicsChange(
                                "specifyHeatOrColdApplicationEffect",
                                e.target.value
                              )
                            }
                          ></Input>
                        )}
                      </React.Fragment>
                    )
                  )}
                </FormGroup>
              </Stack>
              <Stack>
                <Typography variant="h6">Quality</Typography>
                <FormGroup>
                  {CHARACTERISTICS["quality"].map((item, index) => (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "quality"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("quality", item)
                          }
                        />
                      }
                      key={index}
                      label={item}
                      value={item}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography variant="h6">Region/Radiation</Typography>
                <FormGroup>
                  {CHARACTERISTICS["regionRadiation"].map((item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "regionRadiation"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange(
                                "regionRadiation",
                                item
                              )
                            }
                          />
                        }
                        label={item}
                        value={item}
                      />
                      {index === 0 && (
                        <Input
                          placeholder="Specify area"
                          disabled={
                            pains[selectedPainIndex]["characteristics"][
                              "regionRadiation"
                            ] !== "Localized to one specific area"
                          }
                          value={
                            pains[selectedPainIndex]["characteristics"][
                              "specifyLocalizedArea"
                            ]
                          }
                          onChange={(e) =>
                            handleCharacteristicsChange(
                              "specifyLocalizedArea",
                              e.target.value
                            )
                          }
                        ></Input>
                      )}
                    </React.Fragment>
                  ))}
                </FormGroup>
              </Stack>
              <Stack>
                <Typography variant="h6">Severity</Typography>
                <FormGroup>
                  {CHARACTERISTICS["severity"].map((item, index) => (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "severity"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("severity", item)
                          }
                        />
                      }
                      key={index}
                      label={item}
                      value={item}
                    />
                  ))}
                </FormGroup>
              </Stack>
              <Stack>
                <Typography variant="h6">Timing</Typography>
                <FormGroup>
                  {CHARACTERISTICS["timing"].map((item, index) => (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            pains[selectedPainIndex]["characteristics"][
                              "timing"
                            ] === item
                          }
                          onClick={() =>
                            handleCharacteristicsChange("timing", item)
                          }
                        />
                      }
                      key={index}
                      label={item}
                      value={item}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography variant="h6">Understanding</Typography>
                <FormGroup>
                  {CHARACTERISTICS["understanding"].map((item, index) => (
                    <React.Fragment key={index}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              pains[selectedPainIndex]["characteristics"][
                                "understanding"
                              ] === item
                            }
                            onClick={() =>
                              handleCharacteristicsChange("understanding", item)
                            }
                          />
                        }
                        key={index}
                        label={item}
                        value={item}
                      />
                      {index === 4 && (
                        <Input
                          placeholder="Specify"
                          disabled={
                            pains[selectedPainIndex]["characteristics"][
                              "understanding"
                            ] !== "Other reason"
                          }
                          value={
                            pains[selectedPainIndex]["characteristics"][
                              "specifyUnderstanding"
                            ]
                          }
                          onChange={(e) =>
                            handleCharacteristicsChange(
                              "specifyUnderstanding",
                              e.target.value
                            )
                          }
                        ></Input>
                      )}
                    </React.Fragment>
                  ))}
                </FormGroup>
              </Stack>
              <Stack>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={pendingHPI}
                  disabled={!characteristicsStatus}
                  endIcon={<SendIcon />}
                  loadingPosition="end"
                  onClick={handleSubmit}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        )}
    </Stack>
  );
};

export default HPI;
